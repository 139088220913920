import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = { state: 'dashboards', name: 'Dashboards', type: 'link', icon: 'av_timer' };

export const MENU_ITEMS_LUUTRU = {
    state: ['files', 'filesdownloaded'],
    name: 'Files',
    type: 'link',
    icon: 'archive',
};

export const MENU_ITEMS_TAIKHOAN = {
    state: ['account', 'editaccount'],
    name: 'Account',
    type: 'link',
    icon: 'manage_accounts',
};

export const BC_KHDT = {
    state: ['synthetic', 'bao-cao-khdt'],
    name: 'bao_cao_ban_khdt',
    type: 'link',
    icon: 'payments',
};


export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    [MCODES.khdt, BC_KHDT],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    MCODES.khdt,
    MCODES.luutru,
    MCODES.taikhoan,
];

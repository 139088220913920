// CONSTABCE
export const MCODES = {
    dashboard: 'dashboard', // Bảng điều khiển
    
    khdt: 'khdt', // Ban kế hoạch đầu tư

    luutru: 'luutru', // Lưu trữ
    taikhoan: 'taikhoan', // Tài khoản
};

// ROLES

// prettier-ignore
export const MENU_ITEMS_ROLES: Map<string, string[]> = new Map([
    ['admin', [
        MCODES.dashboard, 
        MCODES.khdt,
        MCODES.luutru, MCODES.taikhoan
    ]],

    // KHDT
    ['vnuhcm.khdt.chuyenvien', [
        MCODES.dashboard, 
        MCODES.khdt,
        MCODES.luutru, MCODES.taikhoan
    ]],
    ['vnuhcm.khdt.lanhdao', [
        MCODES.dashboard, 
        MCODES.khdt,
        MCODES.luutru, MCODES.taikhoan
    ]],

]);

// FUNCTIONS
export function menu_items_check_for_role(roles: string[]) {
    let menu_items: Set<string> = new Set();
    roles.forEach((role: string) => {
        let menu_for_role = MENU_ITEMS_ROLES.get(role);
        if (!Array.isArray(menu_for_role)) return;
        menu_for_role.forEach((item: string) => menu_items.add(item));
    });
    return [...menu_items];
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../common-services/account.service';
import { HostnameService } from '../common-services/hostname.service';

@Injectable({ providedIn: 'root' })
export class BaocaoKHDTDanhMucDuAnDataService {
    private _data: any[] = [];
    private _dataSub: Subject<any[]> = new Subject();

    public get data(): any[] {
        return this._data;
    }
    public set data(value: any[]) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any[]> {
        return this._dataSub;
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async fetch() {
        let result = await this.httpClient
            .get<any>(this.hostnameService.VnuisAPI_Readv1 + '/synthetic-khdt-du-an/get-all', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();

        if (result.status == 'success') this.data = result.du_an;
        else this.data = [];

        return this.data;
    }

    async fetchIfNotExists() {
        if (!this.data || Object.keys(this.data).length <= 0) return await this.fetch();
        return this.data;
    }

    getByType(type: string) {
        let values = this._data.filter((row: any) => row.loai == type);
        return values || [];
    }
}

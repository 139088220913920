import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { AccountService } from '../common-services/account.service';
import { HostnameService } from '../common-services/hostname.service';
import { dashboard_check_for_role } from './roles.dashboard.utils';
import { MCODES, menu_items_check_for_role } from './roles.menu.utils';
import { bcth_khdt_check_for_role } from './roles.bcth.khdt.utils';

@Injectable({ providedIn: 'root' })
export class RolesPermissionService {
    private _data: Map<string, any> = new Map();
    private _dataSub: Subject<Map<string, any>> = new Subject();

    get data() {
        return this._data;
    }
    get dataSub() {
        return this._dataSub;
    }

    constructor(protected readonly keyCloakService: KeycloakService) {
        this._data.clear();
        this._data.set('roles', []);
        this._data.set('menu_items', [MCODES.dashboard, MCODES.taikhoan]);
        this._data.set('bcth_khdt', {});
        this._data.set(MCODES.dashboard, {});
    }

    changeForRole(newRoles: string[]) {
        let currentValues: any = this._data.get('roles');
        if (currentValues.length == newRoles.length && newRoles.every((role: string) => currentValues.includes(role)))
            return;

        this._data.set('roles', newRoles);
        this._data.set('menu_items', menu_items_check_for_role(newRoles));
        this._data.set('bcth_khdt', bcth_khdt_check_for_role(newRoles));
        this._data.set(MCODES.dashboard, dashboard_check_for_role(newRoles));
        this._dataSub.next(this._data);
    }
}

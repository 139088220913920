// DASHBOARD ELEMENTS
export const DASHBOARD_ELEMENTS = {
    TOP_CARD_KHDT: 'dashboard-top-card-khdt',
};

// ROLES
export const DASHBOARD_ROLES: Map<string, any> = new Map([
    [
        'admin',
        {
            elements: [DASHBOARD_ELEMENTS.TOP_CARD_KHDT],
        },
    ],
    // KHDT
    [
        'vnuhcm.khdt.chuyenvien',
        {
            elements: [DASHBOARD_ELEMENTS.TOP_CARD_KHDT],
        },
    ],
    [
        'vnuhcm.khdt.lanhdao',
        {
            elements: [DASHBOARD_ELEMENTS.TOP_CARD_KHDT],
        },
    ],
]);

// FUNCTIONS
export function dashboard_check_for_role(roles: string[]) {
    let elements: Set<string> = new Set();

    roles.forEach((role: string) => {
        let role_info = DASHBOARD_ROLES.get(role);
        if (!role_info) return;
        role_info.elements.forEach((item: string) => elements.add(item));
    });

    return {
        elements: [...elements],
    };
}

import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './helper/auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        data: {
            someRoles: ['admin', 'vnuhcm.khdt.chuyenvien', 'vnuhcm.khdt.lanhdao'],
        },
        children: [
            {
                path: '',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboards',
                loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
            },

            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },

            // Synthetic
            {
                path: 'synthetic',
                loadChildren: () => import('./synthetic/synthetic.module').then((m) => m.SyntheticModule),
            },

            // Utitlitis
            {
                path: 'utitlities',
                loadChildren: () => import('./utilities/utilities.module').then((m) => m.UtilitiesModule),
            },

            // Files
            {
                path: 'files',
                loadChildren: () => import('./files/files.module').then((m) => m.FilesModule),
            },
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: () =>
                    import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'authentication/404',
    },
];
